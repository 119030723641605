import { ChevronLeft, Person } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useIsMobile } from "../Hooks/useIsMobile";
import { iconBorderStyle } from "../Styles/IconStyle";
import { SiteInformation } from "../Types/SessionType";
import { UserType } from "../Types/UserType";

interface HeaderProps {
	user?: UserType;
	siteInformation?: SiteInformation;
}

const Header = ({ user, siteInformation }: HeaderProps) => {
	const isMobile = useIsMobile();
	const defaults: { user: UserType; siteInformation: SiteInformation } = {
		user: {
			reference: "1",
			type: null,
			email: null,
			accessToken: null,
			phoneNumber: "",
			id: null,
			hasCredit: false,
		},
		siteInformation: {
			reference: "1",
			logo: "https://www.eden.co.uk/staticimages/logo_trans.png",
			favicon: "https://www.eden.co.uk/favicon.ico",
			backUrl: "https://www.eden.co.uk/shop/basket.php",
			successUrl: "https://www.eden.co.uk/secure/payment/new-checkout/",
			logoutUrl:
				"https://www.eden.co.uk/secure/myeden/logout.php?r=https%3A%2F%2Fwww.eden.local%2Fsecure%2Fpayment%2F%3FnewCheckoutProject%3Denable",
			contactNumber: "0345 222 3336",
			openingTimes: "08:30 - 17:30 Monday to Friday",
		},
	};

	const userSafe = user ?? defaults.user;
	const siteInformationSafe = siteInformation ?? defaults.siteInformation;

	const style = {
		padding: "1em",
		bottomMargin: "2em",
	};
	const chevronStyle = {
		color: "rgba(0, 0, 0, 0.54)",
	};

	const imageStyle = {
		width: 55.63,
		height: 40,
	};

	return (
		<Box
			style={{
				backgroundColor: "white",
				marginBottom: isMobile ? "0em" : "2em",
			}}
		>
			<Grid container style={style}>
				<Grid size={4}>
					<IconButton
						style={{
							borderStyle: "solid",
							borderColor: "rgba(0, 0, 0, 0.23)",
							borderWidth: 1,
						}}
						onClick={() => {
							window.location.href = siteInformationSafe.backUrl;
						}}
					>
						<ChevronLeft sx={chevronStyle} fontSize="medium" />
					</IconButton>
				</Grid>

				<Grid size={4} textAlign="center">
					<Box
						component="img"
						style={imageStyle}
						src={siteInformationSafe.logo}
						alt="Eden Logo"
					/>
				</Grid>

				<Grid
					size={4}
					style={{
						display: "flex",
						justifyContent: "end",
						alignItems: "center",
						columnGap: "1em",
					}}
				>
					<Typography variant="body2" fontWeight={600}>
						Need help?
					</Typography>
					{!isMobile && (
						<Typography variant="body2">
							Call {siteInformationSafe.contactNumber}
						</Typography>
					)}

					{!isMobile && (
						<Typography
							variant="body2"
							style={{ color: "rgba(0, 0, 0, 0.6)" }}
						>
							{siteInformationSafe.openingTimes}
						</Typography>
					)}

					{userSafe.type === "registered" && (
						<Person
							style={{
								color: "rgba(0, 0, 0, 0.54)",
								padding: 4,
								...iconBorderStyle,
							}}
						/>
					)}
				</Grid>
			</Grid>
			<Divider></Divider>
		</Box>
	);
};

export default Header;
