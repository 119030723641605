import { Grid, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Address } from "../../../Types/AddressTypes";

interface AddressFormatViewProps {
	address: Address;
}

function AddressFormatView({ address }: AddressFormatViewProps): ReactElement {
	return (
		<Grid container sx={{ paddingLeft: "2em" }}>
			<Grid size={12}>
				<Typography variant="body2">{address.fullName}</Typography>
			</Grid>
			{address.organisation !== "" && (
				<Grid size={12}>
					<Typography variant="body2">
						{address.organisation}
					</Typography>
				</Grid>
			)}
			<Grid size={12}>
				<Typography variant="body2">{address.addressLine1}</Typography>
			</Grid>
			<Grid size={12}>
				<Typography variant="body2">{address.addressLine2}</Typography>
			</Grid>
			<Grid size={12}>
				<Typography variant="body2">
					{address.city}, {address.postcode}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default AddressFormatView;
