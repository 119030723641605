import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormGroup,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useDialogWidth } from "../../../Hooks/useDialogWidth";
import { iconBorderStyle } from "../../../Styles/IconStyle";
import { Address } from "../../../Types/AddressTypes";
import { Country } from "../../../Types/SessionType";
import { log } from "../../../Utilities/backendRequests";
import GreenButton from "../../GreenButton";

interface ManualAddressDialogProps {
	address?: Address;
	open: boolean;
	handleClose: Function;
	onAddressEntered: (address: Address) => Promise<void>;
	countryCodes: Country[];
}

function ManualAddressDialog({
	address,
	open,
	handleClose,
	onAddressEntered,
	countryCodes,
}: ManualAddressDialogProps): ReactElement {
	const dialogWidth = useDialogWidth();
	const defaultAddress: Address = {
		addressLine1: "",
		addressLine2: "",
		addressLine3: "",
		city: "",
		countryCode: countryCodes[0].code,
		fullName: "",
		postcode: "",
		organisation: "",
	};

	const titleStyle = {
		display: "flex",
		alignItems: "center",
	};

	const contentStyle = {
		display: "flex",
		flexDirection: "column" as "column",
		justifyContent: "space-bewtween",
	};

	const inputStyle = {
		marginBottom: "1em",
	};

	const validationSchema = Yup.object().shape({
		fullName: Yup.string().required("Full Name is required!"),
		addressLine1: Yup.string().required("Address Line 1 is required!"),
		addressLine2: Yup.string(),
		addressLine3: Yup.string(),
		postcode: Yup.string().required("Postcode is required!"),
		organisation: Yup.string(),
		city: Yup.string().required("City is required!"),
		countryCode: Yup.string().required("Country code is required"),
	});

	address = address ?? defaultAddress;

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(validationSchema),
		reValidateMode: "onSubmit",
	});

	const submit = handleSubmit(async (data) => {
		log("manual-address-entered", "click", { data: data });
		await onAddressEntered(data).catch(() => handleClose());
		handleClose();
	});

	return (
		<Dialog
			open={open}
			onClose={() => {
				reset();
				handleClose();
			}}
			{...dialogWidth}
		>
			<DialogTitle>
				<Grid container style={titleStyle}>
					<Grid size={10}>
						<Typography fontWeight={600}>Delivery Details</Typography>
					</Grid>
					<Grid size={2} sx={{ display: "flex", justifyContent: "end" }}>
						<IconButton onClick={() => handleClose()} style={iconBorderStyle}>
							<Close></Close>
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<Divider></Divider>

			<DialogContent>
				<form onSubmit={submit} style={contentStyle}>
					<FormGroup style={inputStyle}>
						<TextField
							label="Full Name"
							defaultValue={address?.fullName ?? ""}
							{...register("fullName")}
							error={!!errors.fullName}
							helperText={errors.fullName?.message ?? ""}
						/>
					</FormGroup>

					<FormGroup style={inputStyle}>
						<TextField
							label="Organisation"
							defaultValue={address?.organisation ?? ""}
							{...register("organisation")}
							error={!!errors.organisation}
							helperText={errors.organisation?.message ?? ""}
						></TextField>
					</FormGroup>

					<FormGroup style={inputStyle}>
						<TextField
							label="Address Line 1"
							defaultValue={address?.addressLine1 ?? ""}
							{...register("addressLine1")}
							error={!!errors.addressLine1}
							helperText={errors.addressLine1?.message ?? ""}
						></TextField>
					</FormGroup>

					<FormGroup style={inputStyle}>
						<TextField
							label="Address Line 2"
							defaultValue={address?.addressLine2 ?? ""}
							{...register("addressLine2")}
							error={!!errors.addressLine2}
							helperText={errors.addressLine2?.message ?? ""}
						></TextField>
					</FormGroup>

					<FormGroup style={inputStyle}>
						<TextField
							label="Address Line 3"
							defaultValue={address?.addressLine3 ?? ""}
							{...register("addressLine3")}
							error={!!errors.addressLine3}
							helperText={errors.addressLine3?.message ?? ""}
						></TextField>
					</FormGroup>

					<FormGroup style={inputStyle}>
						<TextField
							label="City"
							defaultValue={address?.city ?? ""}
							{...register("city")}
							error={!!errors.city}
							helperText={errors.city?.message ?? ""}
						></TextField>
					</FormGroup>

					<FormControl style={inputStyle}>
						<InputLabel id="country-select">Country</InputLabel>
						<Select
							defaultValue={address?.countryCode}
							id="country-select"
							label="Country"
							{...register("countryCode")}
						>
							{countryCodes.map((country: Country) => {
								return (
									<MenuItem key={country.code} value={country.code}>
										{country.country}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<FormGroup style={inputStyle}>
						<TextField
							label="Postcode"
							defaultValue={address?.postcode ?? ""}
							{...register("postcode")}
							error={!!errors.postcode}
							helperText={errors.postcode?.message ?? ""}
						></TextField>
					</FormGroup>

					<GreenButton
						type="submit"
						text="Set delivery details"
						style={{ textTransform: "uppercase", fontWeight: 600 }}
						loading={isSubmitting}
						disabled={isSubmitting}
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default ManualAddressDialog;
