import { KeyboardArrowDown } from "@mui/icons-material";
import {
	Box,
	Container,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { CheckoutState } from "../Types/CheckoutType";

interface AccordionRowProps {
	children: ReactNode;
	icon: ReactElement;
	expandable: boolean;
	expandOnClick: Function;
	typography: string;
	state: CheckoutState;
	testId?: string;
}

function AccordionRow({
	icon,
	typography,
	expandable,
	expandOnClick,
	children,
	state,
	testId,
}: AccordionRowProps) {
	const rowStyle = {
		borderBottom: "1px solid #21212114",
		// minHeight: '3.75em', //60/16
		padding: "1em 0 1em 0",
		display: "flex",
		alignItems: "center",
	};

	return (
		<div style={rowStyle} data-testid={testId}>
			<Container
				onClick={() => {
					if (state === "SET") {
						expandOnClick();
					}
				}}
			>
				<Grid container>
					<Grid size={11}>
						<Stack
							direction={"row"}
							spacing={1}
							alignItems="center"
						>
							<Box
								style={
									state === "CURRENT" || state === "EDIT"
										? { color: "#2E7D32" }
										: state === "FUTURE"
										? { color: "grey" }
										: {}
								}
							>
								{icon}
							</Box>
							<Typography
								align="justify"
								fontWeight={"600"}
								style={
									state === "FUTURE" ? { color: "grey" } : {}
								}
							>
								{typography}
							</Typography>
						</Stack>
					</Grid>
					<Grid size={1} textAlign={"center"}>
						{expandable === true && (
							<IconButton
								onClick={() => {
									expandOnClick();
								}}
							>
								<KeyboardArrowDown></KeyboardArrowDown>
							</IconButton>
						)}
					</Grid>
				</Grid>
				{children}
			</Container>
		</div>
	);
}

export default AccordionRow;
