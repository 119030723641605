import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid } from "@mui/material";
import braintree, { Client, ThreeDSecure } from "braintree-web";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useBillingAddressSelector } from "../../../Hooks/useBillingAddressSelector";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { useIsMobile } from "../../../Hooks/useIsMobile";
import { useOnNonceReceived } from "../../../Hooks/useOnNonceReceived";
import { Session } from "../../../Types/SessionType";
import { handleAxiosError, log } from "../../../Utilities/backendRequests";
import { threeDSecureVerify } from "../../../Utilities/braintreeFunctions";
import GreenButton from "../../GreenButton";
import BillingAddressSelector from "./BillingAddressSelector";
import "./braintreeHostedField.css";
import { Controller, useForm } from "react-hook-form";
import { captureException } from "@sentry/core";

const Card = ({
	braintreeClient,
	braintree3dSecureClient,
	sessionData,
	deviceData,
	setTakingPayment,
	setErrors,
}: {
	braintreeClient: Client;
	braintree3dSecureClient: ThreeDSecure;
	deviceData: string | null;
	sessionData: Session;
	setTakingPayment: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
	const [fetching, setFetching] = useState(false);
	const [createIframe, setCreateIFrame] = useState(true);
	const [force3DSecure, setForce3DSecure] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const force3DSecureRef = useRef<boolean>(force3DSecure);
	const formatter = useCurrencyFormatter();
	const queryClient = useQueryClient();
	const isMobile = useIsMobile();
	const billingSelectorForm = useBillingAddressSelector(sessionData);
	const saveCardForm = useForm({
		defaultValues: {
			savedCard: !!(sessionData.user.id && sessionData.settings.transactionSource !== 'moto')
		}
	})

	//useRef is used for this as otherwise we will not get the update value of
	//force3DSecure in the onSubmit event function
	force3DSecureRef.current = force3DSecure;

	const { payAndVerify } = useOnNonceReceived(
		setErrors,
		setTakingPayment,
		deviceData,
		() => {
			setForce3DSecure(true);
		}
	);

	const [cardErrors, setCardErrors] = useState({
		number: false,
		cvv: false,
		expirationDate: false,
	});

	useEffect(() => {
		if (!createIframe) {
			return;
		}

		setCreateIFrame(false);
		braintree.hostedFields.create(
			{
				client: braintreeClient,
				fields: {
					number: {
						container: "#card-number",
						placeholder: "Card Number",
					},
					cvv: {
						container: "#cvv",
						placeholder: "CVV",
					},
					expirationDate: {
						container: "#expiration-date",
						placeholder: "Expiration Date",
					},
				},
				styles: {
					input: {
						"font-size": "16px",
						color: "#3A3A3A",
						height: "14px",
					},
				},
			},
			(hostedFieldsErr, hostedFieldsInstance) => {
				if (hostedFieldsErr || undefined === hostedFieldsInstance) {
					console.error(hostedFieldsErr);
					return;
				}

				hostedFieldsInstance.on("validityChange", (event) => {
					let newErrors = {
						number: !event.fields["number"].isPotentiallyValid,
						cvv: !event.fields["cvv"].isPotentiallyValid,
						expirationDate:
							!event.fields["expirationDate"].isPotentiallyValid,
					};

					setCardErrors(newErrors);
				});

				formRef.current?.addEventListener("submit", async (event) => {
					event.preventDefault();

					setErrors([]);
					setFetching(true);
					setTakingPayment(true);

					log("pay-by-card", "click", {});
					const { trigger } = billingSelectorForm.props.form;
					const valid = await trigger();
					if (!valid) {
						setFetching(false);
						setTakingPayment(false);
						setErrors(["Billing address invalid. Please check billing address and try again."]);
						return;
					}

					let session: Session | null =
						await billingSelectorForm.util.updateBillingDetails(
							setErrors
						);

					let newErrors = {
						number: false,
						cvv: false,
						expirationDate: false,
					};

					if (session === null) {
						setFetching(false);
						setTakingPayment(false);
						setErrors(["Failed to set billing address"]);
						setCardErrors(newErrors);
						return;
					}

					queryClient.setQueryData("session", session);

					hostedFieldsInstance.tokenize(async function (
						tokenizeErr,
						payload
					) {
						if (tokenizeErr || payload === undefined) {
							let invalidFieldKeys =
								tokenizeErr?.details?.invalidFieldKeys;

							if (undefined === invalidFieldKeys) {
								invalidFieldKeys = [
									"number",
									"cvv",
									"expirationDate",
								];
							}

							invalidFieldKeys.forEach(
								(
									error: "number" | "cvv" | "expirationDate"
								) => {
									newErrors[error] = true;
								}
							);

							setFetching(false);
							setTakingPayment(false);
							setCardErrors(newErrors);
							return;
						}

						if (null === session) {
							setFetching(false);
							setTakingPayment(false);
							setErrors([
								"Failed to set billing address. Please try again",
							]);
							return;
						}

						try {
							if (!session.settings.threeDSecure) {
								await payAndVerify(
									payload.nonce,
									"card",
									session,
									"",
									saveCardForm.watch("savedCard")
								);
							} else {
								setTakingPayment(true);
								const threeDPayload = await threeDSecureVerify(
									session.costs.total,
									braintree3dSecureClient,
									payload.nonce,
									payload.details.bin,
									session.addresses[
										session.selectedBillingAddress ?? 0
									],
									session.addresses[
										session.selectedDeliveryAddress ?? 0
									],
									session.user.phoneNumber ?? "",
									session.user.email ?? "",
									force3DSecureRef.current
								);
								await payAndVerify(
									threeDPayload.nonce,
									"card",
									session,
									"",
									saveCardForm.watch("savedCard")
								);
							}
						} catch (error) {
							captureException(error);
							setFetching(false);
							setTakingPayment(false);
							handleAxiosError(setErrors).onError(error);
						}

						setFetching(false);
					});
				});
			}
		);
	}, [
		braintreeClient,
		braintree3dSecureClient,
		queryClient,
		sessionData.addresses,
		sessionData.selectedBillingAddress,
		sessionData.selectedDeliveryAddress,
		sessionData.costs.total,
		setErrors,
		payAndVerify,
		createIframe,
		billingSelectorForm.props,
		billingSelectorForm.util,
		setTakingPayment,
		saveCardForm,
	]);

	const isDesktop = !isMobile;

	return (
		<form method="POST" id="card-payment-form" ref={formRef}>
			<div id="card-number" className="hosted-field"></div>

			<FormHelperText error={true} style={{ marginBottom: "1em" }}>
				{cardErrors.number ? "Invalid Card Number" : ""}
			</FormHelperText>

			<Grid container>
				<Grid size={isDesktop ? 6 : 12}>
					<div
						id="expiration-date"
						className="hosted-field"
						style={isDesktop ? { marginRight: "0.5em" } : {}}
					></div>
					<label htmlFor="expiration-date">MM/YYYY</label>
					<FormHelperText error={true}>
						{cardErrors.expirationDate
							? "Invalid Expiration Date"
							: ""}
					</FormHelperText>
				</Grid>

				<Grid size={isDesktop ? 6 : 12}>
					<div
						id="cvv"
						className="hosted-field"
						style={isDesktop ? { marginLeft: "0.5em" } : {}}
					></div>
					<label htmlFor="cvv">3 digits</label>
					<FormHelperText error={true}>
						{cardErrors.cvv ? "Invalid cvv" : ""}
					</FormHelperText>
				</Grid>
			</Grid>

			{sessionData.settings.transactionSource !== 'moto' && sessionData.user.id && <FormGroup>
				<Controller
					control={saveCardForm.control}
					name="savedCard"
					render={ ({ field }) => {
						return <FormControlLabel
							control={<Checkbox {...field} checked={field.value} />}
							label="Save payment for future orders"
						/>
					}}
				/>
			</FormGroup>}

			<BillingAddressSelector {...billingSelectorForm.props} />

			<GreenButton
				id="payment-submit"
				type="submit"
				text={"Pay " + formatter.format(sessionData.costs.total)}
				loading={fetching}
				disabled={fetching}
				style={{ marginTop: "1em" }}
			/>
		</form>
	);
};

export default Card;
