import { Grid } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useSetUserAsGuestMutation } from "../../../Hooks/Mutations/useSetUserAsGuestMutation";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { useOnNonceReceived } from "../../../Hooks/useOnNonceReceived";
import { ExpressCheckoutProps } from "../../../Types/CheckoutType";
import { Session } from "../../../Types/SessionType";
import { UserType } from "../../../Types/UserType";
import { axiosError, log } from "../../../Utilities/backendRequests";
import GreenButton from "../../GreenButton";
import ExpressCheckout from "../Payment/ExpressCheckout";
import ContinueAsGuestForm from "./ContinueAsGuestForm";

interface AccountFormProps {
	user: UserType;
	completeFunction: Function;
	sessionData: Session;
	expressCheckout?: ExpressCheckoutProps;
	deviceData: string | null;
}

function AccountForm({
	user,
	completeFunction,
	sessionData,
	expressCheckout,
	deviceData,
}: AccountFormProps) {
	const { errorMessage, setErrors, clearErrors } = useErrorMessage({
		marginLeft: "1em",
		width: "100%",
	});
	const [loginFetching, setLoginFetching] = useState(false);
	const [email, setEmail] = useState(user.email ?? "");
	const [processingPayment, setProcessingPayment] = useState(false);
	const { payAndVerify } = useOnNonceReceived(
		setErrors,
		setProcessingPayment,
		deviceData
	);

	const queryClient = useQueryClient();
	const onMutationErrorHandler = (response: any) => {
		axiosError(setErrors, response);
	};

	const { setUserAsGuest } = useSetUserAsGuestMutation(
		queryClient,
		onMutationErrorHandler
	);

	const token = localStorage.getItem("token");
	const redirect = () => {
		axios
			.post(process.env.REACT_APP_BACKEND_API_URL + "/url/login", {
				token: token,
				username: email,
			})
			.then((data) => {
				let url = data.data.data.url;
				const state = data.data.data.state;
				localStorage.setItem("oauthState", state);

				window.location.href = url;
				setLoginFetching(false);
			})
			.catch(() => {
				setErrors(["Login failed"]);
				setLoginFetching(false);
			});
	};

	return (
		<>
			<Grid container mt={1} spacing={2} borderTop={"10px #000"}>
				{errorMessage}

				<ContinueAsGuestForm
					email={email}
					setEmail={setEmail}
					onSubmit={async (email: string) => {
						await setUserAsGuest({ email: email });
						clearErrors();
						completeFunction();
					}}
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						gap: "1em",
						marginLeft: "1em",
						marginTop: "1em",
					}}
					disableContinue={processingPayment}
				/>

				<GreenButton
					text="Login"
					loading={loginFetching}
					disabled={loginFetching || processingPayment}
					loadingStyle={{ color: "#2E7D32" }}
					onClick={async () => {
						setLoginFetching(true);
						//log  request potentially might not complete before redirect happens so await it.
						await log("login", "click", {});
						redirect();
					}}
					style={{
						color: "#2E7D32",
						backgroundColor: "white",
						border: "1px solid rgba(46, 125, 50, 0.5)",

						marginLeft: "1em",
						marginTop: "1em",
					}}
					data-testid="guestLogin"
				/>

				{expressCheckout && sessionData.collectDeliveryDetails && (
					<Grid size={12} style={{ paddingTop: 0 }}>
						<ExpressCheckout
							{...expressCheckout}
							payAndVerify={payAndVerify}
							sessionData={sessionData}
							processingPayment={processingPayment}
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
}

export default AccountForm;
